import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import Donate from "./home/donate"

export default function HeaderMobile({ open, data }) {
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        static
        className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <a href="/">
                  <StaticImage
                    src="../images/logo.png"
                    alt="PEJC.org logo"
                    className=" w-auto"
                  />
                </a>
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid grid-cols-1 gap-7">
                {data.wpMenu.menuItems.menu.map(item => (
                  <a
                    key={item.id}
                    href={item.url}
                    className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                  >
                    <div className="ml-4 text-base font-medium text-green-900">
                      {item.label}
                    </div>
                  </a>
                ))}
              </nav>
            </div>
          </div>

          <div className="lg:hidden p-5 flex justify-center">
            <div className="w-auto items-center flex">
              <Donate />
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  )
}
