import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import HeaderMobile from "./header-mobile"
import { Popover } from "@headlessui/react"
import { MenuIcon } from "@heroicons/react/outline"
import Donate from "./home/donate"
import { useWPMenu } from "../hooks/useWPMenu"
import cleanUrl from "../lib/cleanUrl"
import { MailIcon } from "@heroicons/react/solid"
import Link from "gatsby-link"

export default function Header() {
  const menu = useWPMenu()
  const windowGlobal = typeof window !== "undefined" && window
  const currentUrl = cleanUrl(windowGlobal.location?.pathname)
  const normalMenu = "text-base nav-header menu-items p-5"
  const highlightedMenu = `${normalMenu} current-page`

  return (
    <Popover className="sticky top-0 z-50 relative bg-white">
      {({ open }) => (
        <>
          <header className="px-0 mx-0 container mx-auto mb-2 flex flex-col justify-center max-w-7xl mx-auto">
            <div className="flex md:justify-start items-center">
              <a href="/" className="sm:ml-2 sm:mr-2">
                <StaticImage
                  src="../images/logo.png"
                  alt="PEJC.org logo"
                  className="w-auto"
                />
              </a>

              <div className="flex-1 hidden items-stretch justify-center lg:flex">
                {menu.wpMenu.menuItems.menu.map(item => (
                  <div className="" key={item.id}>
                    <Link
                      to={item.url}
                      className={
                        currentUrl === cleanUrl(item.url)
                          ? highlightedMenu
                          : normalMenu
                      }
                    >
                      {item.label}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="lg:hidden right-5 flex-1 flex justify-end">
                <Popover.Button
                  className="items-align-end bg-white rounded-md p-2 inline-flex items-center
												justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100
												focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              <div className="w-auto hidden items-center justify-end lg:flex">
                <Donate />
              </div>
            </div>
            <HeaderMobile data={menu} />
          </header>
        </>
      )}
    </Popover>
  )
}
