import React from "react"

export default function Donate() {
  return (
    <>
      <a
        href="/be-a-volunteer"
        className="ml-1 text-center w-36 px-2 py-1 border border-transparent shadow-sm text-base font-medium text-white bg-green-900 hover:bg-green-700"
      >
        Be a Volunteer
      </a>
      <a
        href="/donate"
        className="ml-1 text-center w-24 px-2 py-1 border border-transparent shadow-sm text-base font-medium text-white bg-green-900 hover:bg-green-700"
      >
        Donate
      </a>
      <a
        href="/online-help-desk"
        className="ml-1 text-center w-24 px-2 py-1 border border-transparent shadow-sm text-base font-medium text-white bg-green-900 hover:bg-green-700"
      >
        Help Desk
      </a>
    </>
  )
}
