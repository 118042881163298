import { graphql, useStaticQuery } from "gatsby"

export const useWPMenu = () => {
  return useStaticQuery(
    graphql`
      query GetMenu {
        wpMenu(id: { eq: "dGVybToyNA==" }) {
          name
          menuItems {
            menu: nodes {
              id
              label
              url
            }
          }
        }
      }
    `
  )
}
